<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Postes #{{ formData.id }} 
          <!-- <div  class="d-flex justify-content-between"> -->
            <!-- <button class="btn btn-danger mx-2" type="button" @click.prevent="DeleteLine()">
          <i class="fas fa-close"></i> Supprimer
        </button>
        <button class="btn btn-primary align-items-end" type="submit">
          <i class="fas fa-floppy-disk"></i> Mettre à jour
        </button> -->

      <!-- </div> -->
        </div>

        <div v-if="formState == 'Create'">Create Postes</div>
      </template>

      <EditPostes v-if="formState == 'Update'" :key="formKey" :contratsclientsData="contratsclientsData"
                  :data="formData"
                  :gridApi="formGridApi" :modalFormId="formId" :pointeusesData="pointeusesData"
                  :sitesData="sitesData" @close="closeForm"/>


      <CreatePostes v-if="formState == 'Create'" :key="formKey" :contratsclientsData="contratsclientsData"
                    :gridApi="formGridApi"
                    :modalFormId="formId" :pointeusesData="pointeusesData" :sitesData="sitesData"
                    @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <div class="childBlock">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :extrasData="extrasData"
                   :getRowStyle="getRowStyle" :maxBlocksInCache="maxBlocksInCache" :pagination="pagination"
                   :paginationPageSize="paginationPageSize"

                   :defaultColumnsOrder="['zones.libelle','clients.libelle','sites.libelle','postes.libelle']"
                   :rowData="rowData" :rowModelType="rowModelType" :url="url"
                   className="ag-theme-alpine" domLayout='autoHeight' rowSelection="multiple"
                   @gridReady="onGridReady">
        <template #header_buttons>
          <div class="btn btn-primary" @click="openCreate"><i
              class="fa fa-plus"></i> Ajouter un poste
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';



export default {
  name: 'PostesView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreatePostes: () => import( "./CreatePostes.vue"),
    EditPostes: () => import( "./EditPostes.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
  },

  data() {

    return {
      formId: "postes",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/postes-Aggrid1',
      table: 'postes',
      contratsclientsData: [],
      zoneselectionner: [],
      typeselectionner: [],
      zonesget: [],
      typesget: [],
      clientsData: [],
      zonesData: [],
      pointeusesData: [],
      sitesData: [],
      statutsData: [],
      requette: 3,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      couvertures_photo: [],
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },

    extrasData: function () {
      // let retour = {}
      let params = {}
      if (!this.zoneselectionner) {
        // params["id"] = { values: [0], filterType: "set" };
      }
      this.tableKey++;

      return {
        baseFilter: params,
        zoneselectionner: this.zoneselectionner,
        typeselectionner: this.typeselectionner,
      };

    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/postes-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;
    this.statutsData = [
      'entierement couvert',
      'partiellement couvert',
      'non couvert'
    ];


  },
  beforeMount() {
    this.columnDefs =
        [
          {
            field: "postes.id",
            sortable: true,


            floatingFilter: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: '#Id',
          },
          {
            field: null,
            headerName: '',
            suppressCellSelection: true,
            minWidth: 80, maxWidth: 80,
            pinned: 'left',
            cellRendererSelector: params => {
              return {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                    this.showForm('Update', field, params.api, "xl")
                  },
                  render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                }
              };
            },

          },
          {
            field: "postes.libelle",
            sortable: true,


            floatingFilter: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'libelle',
            width: 200,
          },
          {
            headerName: 'site',
            field: 'sites.libelle',

            sortable: true,


            floatingFilter: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            join: {
              table: 'sites',
              champ2: 'sites.id',
              champ1: 'postes.site_id',
              operateur: '=',
            }
          },
          {
            headerName: 'client',
            field: 'clients.libelle',
            sortable: true,


            floatingFilter: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            join: {
              table: 'clients',
              champ2: 'clients.id',
              champ1: 'sites.client_id',
              operateur: '=',
            }
          },
          {
            headerName: 'zone',
            field: 'zones.id',

            sortable: true,
            filter: "FiltreEntete",

            filterParams: {
              url: this.axios.defaults.baseURL + '/api/zones-Aggrid1',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
            join: {
              table: 'zones',
              champ2: 'zones.id',
              champ1: 'sites.zone_id',
              operateur: '=',
            }
          },
          {
            headerName: 'zone',
            field: 'zones.libelle',

            floatingFilter: true,
            filter: 'agTextColumnFilter',
            sortable: true,
          },
          {
            hide: true,
            field: 'maxjours'
          },
          {
            hide: true,
            field: 'maxnuits'
          },
          {
            field: "Agentjour",
            sortable: false,
            minWidth: 100, maxWidth: 100,
            cellRendererSelector: params => {
              let agentjour = 0;
              let maxjour = 0;
              try {
                agentjour = params.data.Agentjour

              } catch (e) {
              }
              try {
                maxjour = params.data.maxjours
              } catch (e) {
              }
              let retour = {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                  },
                  render: `<div class="" style="width:100%;height:100%;border-radius:5px;text-align:center;cursor:default"> ${agentjour}/${maxjour}   </div>`
                }
              }
              return retour;
            },
            headerName: 'Jours',
          },
          {
            field: "Agentnuit",
            sortable: false,
            minWidth: 100, maxWidth: 100,
            cellRendererSelector: params => {
              let agentnuit = 0;
              let maxnuit = 0;
              try {
                agentnuit = params.data.Agentnuit
              } catch (e) {
              }
              try {
                maxnuit = params.data.maxnuits
              } catch (e) {
              }
              let retour = {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                  },
                  render: `<div class="" style="width:100%;height:100%;border-radius:5px;text-align:center;cursor:default"> ${agentnuit}/${maxnuit}   </div>`
                }
              }
              return retour;
            },
            headerName: 'Nuits',
          },
          {
            field: "postes.code",
            sortable: true,


            floatingFilter: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'code',
            hide: true,
          },
          {
            headerName: 'contratsclient',
            field: 'contratsclients.libelle',

            sortable: true,
            hide: true,
            suppressColumnsToolPanel: true,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'contratsclients',
              champ2: 'contratsclients.id',
              champ1: 'postes.contratsclient_id',
              operateur: '=',
            }
          },
          {
            headerName: 'article',
            field: 'postesarticles.libelle',

            floatingFilter: true,
            filter: 'agTextColumnFilter',
            sortable: true,
            suppressColumnsToolPanel: true,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'postesarticles',
              champ2: 'postesarticles.id',
              champ1: 'postes.postesarticle_id',
              operateur: '=',
            }
          },
          {
            field: "jours",
            sortable: true,
            //

            floatingFilter: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'jours couverts',
            hide: true,
          },
          {

            headerName: 'type',
            field: 'typespostes.id',
            filter: "FiltreEntete",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/typespostes-Aggrid1',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
            join: {
              table: 'typespostes',
              champ2: 'typespostes.id',
              champ1: 'postes.typesposte_id',
              operateur: '=',
            }
          },
          {

            headerName: 'type',
            field: 'typespostes.libelle',
            suppressColumnsToolPanel: true,
            floatingFilter: true,
            filter: 'agTextColumnFilter',
          },
          {

            headerName: 'type',
            field: 'typespostes.canCreate',
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {

            headerName: 'type',
            field: 'typespostes.canUpdate',
            hide: true,
            suppressColumnsToolPanel: true,
          },
          {

            headerName: 'type',
            hide: true,
            field: 'typespostes.canDelete',
            suppressColumnsToolPanel: true,
          },
          {
            field: 'status_complets',
            headerName: 'Couverture poste',
            floatingFilter: true,
            suppressCellSelection: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              suppressAndOrCondition: true,
              keyCreator: function (params) {
                let cles = {
                  'Non couvert': 'poste_non_complet',
                  'Partiellement couvert': 'poste_semi_complet',
                  'Entierement couvert': 'poste_complet',
                }
                return cles[params.value]; // la clé est la valeur elle-même
              },
              valueFormatter: function (params) {
                return params.value;
              },
              values: [
                'Non couvert',
                'Partiellement couvert',
                'Entierement couvert',
              ],
              refreshValuesOnOpen: true,
            },
          },


        ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    this.zonesget = this.$route.meta.zonesGet
    this.typesget = this.$route.meta.typesGet
    console.log('this.typesget', this.$route.meta.typesGet);
    // this.getcontratsclients();
    // this.getclients();
    // this.getzones();
    // this.getpointeuses();
    // this.getsites();
    this.couvertures_photo = [
      {
        key: '2',
        value: 'Complets'
      },
      {
        key: '1',
        value: 'Incomplets'
      },
    ];

  },
  methods: {

    getRowStyle(params) {
      let style = {}
      console.log('RowParams===', params)
      try {

        if (params.data.status_complets.toLowerCase() == 'poste_non_complet') {
          style.background = "#ff000070"
        }
        if (params.data.status_complets.toLowerCase() == 'poste_semi_complet') {
          style.background = "#ECA84C"
        }
      } catch (e) {

      }
      return style;
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },

    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    getcontratsclients() {
      this.axios.get('/api/contratsclients').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.contratsclientsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    getclients() {
      this.axios.get('/api/clients').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.clientsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    getzones() {
      this.axios.get('/api/zones').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.zonesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },


    getpointeuses() {
      this.axios.get('/api/pointeuses').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.pointeusesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getsites() {
      this.axios.get('/api/sites').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.sitesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    zoneselect(zone) {
      //   this.actualZone = zone;
      if (this.zoneselectionner.includes(zone)) {
        // Zone is already selected, so we want to deselect it
        const index = this.zoneselectionner.indexOf(zone);
        if (index !== -1) {
          this.zoneselectionner.splice(index, 1); // Remove the zone from the array
        }
      } else {
        // Zone is not selected, so we want to select it
        this.zoneselectionner.push(zone);
      }

      // console.log('this.zoneselectionner', this.zoneselectionner)
    },

    typeselect(type) {
      if (this.typeselectionner.includes(type)) {
        // type is already selected, so we want to deselect it
        const index = this.typeselectionner.indexOf(type);
        if (index !== -1) {
          this.typeselectionner.splice(index, 1); // Remove the zone from the array
        }
      } else {
        // type is not selected, so we want to select it
        this.typeselectionner.push(type);
      }

    },
  }
}
</script>

<style>
.ag-root-wrapper {
  border-radius: 5px
}

.childBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  margin: 10px auto;
}

.newButton {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  right: 30px;
  border-radius: 5px;
  padding: 10px;
  background: #0004ff;
  color: #fff;
}
</style>
